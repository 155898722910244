export default {
  ELECTRICITY_BILL: "Facture d'électricité",
  BANK_IDENTITY_STATEMENT: "RIB du demandeur",
  PROPERTY_DEED: "Preuve de l'existance légale",
  PROPERTY_TAXES: "Taxes foncières",
  NEW_CONSTRUCTION_ELEVATION_PLAN: "Plans de masse",
  NEW_CONSTRUCTION_SITE_PLAN: "Plans de façade",
  NEW_CONSTRUCTION_MAYOR_PROJECT_DECLARATION: "Permis de construire précédent",
  QUOTE: "Devis e-signé",
  INSURANCE: "Assurance habitation",
  SC144: "SC144",
  PANEL_CARBON_FOOTPRINT_COMPLIANCE_CERTIFICATE:
    "Certificat de bilan carbone du panneau solaire inférieur à 550 CO2e/kWc",
  DP1: "Plan de situation",
  DP2: "Plan de masse",
  DP2_UNLABELLED: "Plan de cadastre",
  DP3: "DP3",
  DP3_FULL_LABEL: "Plan de coupe",
  DP4: "DP4",
  DP5: "DP5",
  DP6: "DP6",
  DP7: "DP7",
  DP8: "DP8",
  PC1: "Plan de situation",
  PC2: "Plan de masse",
  PC2_UNLABELLED: "Plan de cadastre",
  PC3: "PC3",
  PC4: "PC4",
  PC5: "PC5",
  PC6: "PC6",
  PC7: "PC7",
  PC8: "PC8",
  DAACT: "DAACT",
  PANEL_LAYOUT: "Calepinage",
  MAYOR_DAACT_APPROVAL: "DAACT (signé)",
  MAYOR_DAACT_CONFIRMATION: "DAACT récépissé",
  MAYOR_PROJECT_DECLARATION: "Déclaration préalable / Permis de construire",
  MAYOR_SENT_PROOF: "Preuve d'envoi",
  MAYOR_RECEIVAL_CONFIRMATION: "Récépissé (Urbanisme)",
  MAYOR_PROJECT_APPROVAL: "Avis de non-opposition (Urbanisme)",
  MAYOR_PROJECT_REJECTION: "Refus (Urbanisme)",
  MAYOR_PROJECT_INSTALLED: "DAACT",
  MAYOR_POSTAL_DELIVERY_CONFIRMATION: "Accusé de récéption",
  MAYOR_DOC: "Déclaration d'ouverture de chantier",
  CONSUEL_CONFIRMATION: "Visa confirmée (Consuel)",
  CONSUEL_APPROVAL: "AC visé (Consuel)",
  WIRING_DIAGRAM: "Schéma électrique unifilaire",
  STRING_DIAGRAM: "Plan de chaînage",
  HARDWARE_SPECIFICATION_LIST: "Bon de travaux",
  ELECTRICAL_SPECIFICATION_CONFORMITY: "Notes de calcul",
  ENVIRONMENTAL_STUDY: "Etude d'impact",
  PRELIMINARY_ENVIRONMENTAL_STUDY: "Étude de cas par cas",
  ENEDIS_F2: "Demande de Raccordement",
  ENEDIS_PDR: "Proposition de raccordement",
  ENEDIS_MISE_COMPLETED: "Preuve de mise en service",
  ENEDIS_MISE_SERVICE_VISIT_SCHEDULED_CONFIRMATION:
    "Preuve de la Mise en Service planifiée",
  ARPE_UNSIGNED: "ARPE",
  ARPE_SIGNED: "ARPE (signé)",
  CONTRACT_UNIQUE_UNSIGNED: "Contract unique",
  CONTRACT_UNIQUE_SIGNED: "Contract unique (signé)",
  ENEDIS_LARGE_PRODUCER_SENT_CONFIRMATION:
    "Preuve de dépôt de la demande de raccordement",
  ENEDIS_LARGE_PRODUCER_DIRECT_CONNECTION_AGREEMENT_UNSIGNED:
    "Convention de raccordement directe",
  ENEDIS_LARGE_PRODUCER_DIRECT_CONNECTION_AGREEMENT_SIGNED:
    "Convention de raccordement directe (signé)",
  ENEDIS_LARGE_PRODUCER_OPERATING_AGREEMENT_UNSIGNED:
    "Convention d’exploitation",
  ENEDIS_LARGE_PRODUCER_OPERATING_AGREEMENT_SIGNED:
    "Convention d’exploitation (signé)",
  DUAL_F1: "Mandat de représentation",
  ELECTRICAL_SAFETY_INSPECTION_REPORT_QUOTE: "Devis du bureau de contrôle",
  ELECTRICAL_SAFETY_INSPECTION_REPORT_QUOTE_SIGNED:
    "Devis du bureau de contrôle (signé)",
  ELECTRICAL_SAFETY_INSPECTION_REPORT: "Rapport de contrôle de conformité DRE",
  ENEDIS_CONSTRUCTION_QUOTE_SIGNED: "Devis de travaux du GDR (signé)",
  ENEDIS_CONSTRUCTION_QUOTE_UNSIGNED: "Devis de travaux du GDR",

  CARD_I_UNSIGNED: "CARD-I",
  CARD_I_SIGNED: "CARD-I (signé)",
  HONOUR_CERTIFICATE: "Attestation sur l’honneur",
  EDF_OA_ELECTRICAL_SAFETY_INSPECTION_QUOTE_UNSIGNED:
    "Devis de rapport ENR de l'organisme inspection",
  EDF_OA_ELECTRICAL_SAFETY_INSPECTION_QUOTE_SIGNED:
    "Devis de rapport ENR de l'organisme inspection (signé)",
  ELECTRICAL_SAFETY_EDF_OA_REPORT: "Attestation de conformité ENR",
  EDF_OA_CONTRACT: "Contrat d’achat EDF OA signé",
  PLATFORM_CONTRACT: "Platform contract",
  PLATFORM_CONTRACT_CONTRACT: "Procès-verbal signé",
  ROOF_LEASING_CONTRACT: "Attestation de mise à disposition",
  ENEDIS_CONSTRUCTION_DOWNPAYMENT_PAYMENT_CONFIRMATION:
    "Preuve du virement de l’acompte",
  ENEDIS_CONSTRUCTION_FINALPAYMENT_PAYMENT_CONFIRMATION:
    "Preuve du virement du solde",
  PV_SYST_STUDY: "PV Syst report",
  PV_SYST_MET: "PV Syst meteorological data",
  PV_SYST_PRJ: "PV Syst project file",
  PV_SYST_VCO: "PV Syst variant configuration file",

  INSURANCE_1: "Attestation de votre assurance responsabilité civile",
  INSURANCE_2: "Attestation de votre assurance décennale",
  PARTNER: "Relevé de compte bancaire (dernier 3 mois)",
  RGE: "Label RGE à jour",
  KBIS: "Extrait Kbis",
  WARRANTY: "Votre garantie de résultat",
  INVERTER_POWER_REGULATION_CERTIFICATE: "Attestation de bridage",
  CONSUEL_WIRING_DIAGRAM: "Schéma électrique unifilaire",
}
